@font-face {
  font-family: 'polycon';
  font-display: swap;
  src: url('../font/polycons/polycon.eot');
  src:
    url('../font/polycons/polycon.eot#iefix') format('embedded-opentype'),
    url('../font/polycons/polycon.ttf') format('truetype'),
    url('../font/polycons/polycon.woff') format('woff'),
    url('../font/polycons/polycon.svg#polycon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='polycon-'],
[class*=' polycon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'polycon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.polycon-advice:before {
  content: '\e900';
}
.polycon-phone:before {
  content: '\e942';
}
.polycon-bubbles2:before {
  content: '\e96d';
}
.polycon-user:before {
  content: '\e008';
}
.polycon-droplet:before {
  content: '\e001';
}
.polycon-thumbsup:before {
  content: '\e032';
}
.polycon-droplet2:before {
  content: '\e005';
}
.polycon-atom:before {
  content: '\e002';
}
.polycon-cart:before {
  content: '\e003';
}
.polycon-checkmark:before {
  content: '\e004';
}
.polycon-home:before {
  content: '\e012';
}
.polycon-paint-format:before {
  content: '\e000';
}
.polycon-arrow-right:before {
  content: '\e006';
}
.polycon-search:before {
  content: '\e007';
}
.polycon-list:before {
  content: '\e009';
}
